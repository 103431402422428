<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn
                    @click="$router.push({name: 'admin.content.create'})"
                    v-text="'Create'"
                    class="mb-4"
                />
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="contents"
            :items-per-page="10"
            class="elevation-1"
            @click:row="handleClickRow">
            <template v-slot:item.is_active="{ item }">
                {{ item.is_active ? 'Yes' : 'No' }}
            </template>
            <template v-slot:item.scopes="{ item }">
                <span v-for="(scope, index) in item.scopes" :key="index">
                    {{ scope }}
                </span>
            </template>
            <template v-slot:item.actions="{ item }">
                <delete-dialog :model="item" type="contents" @success="deleted"/>
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import DeleteDialog from "@/components/dialogs/DeleteDialog";
import Content from "@/models/Content";
import { mapMutations } from "vuex";

export default {
    name: 'content-index',
    components: {
        AdminDashboardLayout,
        DeleteDialog
    },
    data() {
        return {
            loading: false,
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: 'Title', value: 'title' },
                { text: 'Slug', value: 'slug' },
                { text: 'Is active', value: 'is_active' },
                { text: 'Scopes', value: 'scopes' },
                { text: 'Actions', value: 'actions' },
            ],
            contents: []
        }
    },
    mounted() {
        this.fetch()
    },
    methods: {
        ...mapMutations(['showSnackBar']),

        async fetch() {
            this.loading = true
            try {
                this.contents = await Content.get()
            } catch (e) {
                this.showSnackBar({
                    color: 'error',
                    timeout: 3000,
                    text: e.response?.data?.message || 'Error'
                })
            } finally {
                this.loading = false
            }
        },
        handleClickRow(content) {
            this.$router.push({name: 'admin.content.edit', params: {id: content.id}})
        },
        deleted(content) {
            this.contents.splice(this.contents.findIndex(c => c.id === content.id), 1);
        }
    }
}
</script>

<style scoped>

</style>
